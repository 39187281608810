<template>
  <el-form ref="form" label-width="68px">
    <div class="wrap" id="wrap">
      <cardTitleCom cardTitle="查询" id="search-card" style="margin-bottom: 0">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch :filterTimeStr="thisMonth" v-model="queryParams" :getList="getList"
              @isShowHighCom="getIsShowHigh" :class="{ marB10: !showHigh }" dateTimeType='daterange'/>
            <div v-show="showHigh" class="marT10 x-w">
              <el-form-item label="单据编号" prop="billNo" label-width="68px">
                <el-input size="mini" class="inputWidth marR15" placeholder="全部" v-model="queryParams.billNo" />
              </el-form-item>
              <el-form-item label="单据状态" prop="billStatuss">
                <SelectLocal class="marR15" :multiple="true" v-model="queryParams.billStatuss" style="width: 250px"
                  :option="$select({ key: 'bill_status' }).option" />
              </el-form-item>
              <el-form-item label="生产工厂" prop="deptIds">
                <SelectRemote :multiple="true" v-model="queryParams.deptIds" style="width: 250px" :option="
                    $select({
                      key: 'listProduceDept',
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="入库仓库" prop="inStoreIds">
                <SelectRemote class="marR15" :multiple="true" v-model="queryParams.inStoreIds" style="width: 250px"
                  :option="
                    $select({
                      key: 'listStore',
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="生产质检" prop="qualityCheckStatuss">
                <SelectLocal class="marR15" :multiple="true" v-model="queryParams.qualityCheckStatuss"
                  style="width: 250px" :option="{
                    data: qualityStatusList,
                    value: 'qualityStatusId',
                    label: 'qualityStatusName',
                  }" />
              </el-form-item>
              <el-form-item label="成品质检" prop="productCheckStatuss">
                <SelectLocal class="marR15" :multiple="true" v-model="queryParams.productCheckStatuss"
                  style="width: 250px" :option="{
                    data: qualityStatusList,

                    value: 'qualityStatusId',
                    label: 'qualityStatusName',
                  }" />
              </el-form-item>
              <el-form-item label="入库状态" prop="inStoreStatuss">
                <SelectLocal :multiple="true" v-model="queryParams.inStoreStatuss" style="width: 250px" :option="{
                    data: inStoreStatusList,
                    value: 'inStoreId',
                    label: 'inStoreName',
                  }" />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="单据信息" class="orderInfo" :hideCard="true">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isAuditBtn="false" :multiple="multiple" @handleAdd="handleAdd"
              @handleDelete="auditBill('删除')" class="tableBtn">
              <template #specialDiyBtn>
                <div class="x-f marR10">
                  <el-button style="margin-left:10px" class="ProductionTeste" :class="{ opacityDisabled: single }"
                    icon="el-icon-user" size="mini" :disabled="single" @click="getOpenInStore(1)">生产部质检
                  </el-button>
                  <el-button class="endProductTeste" :class="{ opacityDisabled: singleQualityCheck }"
                    icon="el-icon-pie-chart" size="mini" :disabled="singleQualityCheck" @click="getOpenInStore(2)">成品部质检
                  </el-button>
                  <el-button type="primary" size="mini" @click="getOpenInStore(4)">智能配货
                  </el-button>
                  <el-button class="putStorage" :class="{ opacityDisabled: singleInStore }" icon="el-icon-house"
                    size="mini" :disabled="singleInStore" @click="getOpenInStore(3)">入库
                  </el-button>
                  <el-dropdown style="margin-left:10px" trigger="click" split-button type="success" size="mini"
                    icon="el-icon-circle-check" @click="auditBill('审核')" @command="auditBill('反审核')">
                    审核
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="反审核">反审核</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button style="margin-left:10px" type="primary" size="mini" :disabled="ids.length <= 0"
                    @click="getRelevantDocuments">相关单据
                  </el-button>
                  <PrintButton style="margin-left: 8px" :printTemplate="{
                      print: {},
                      label: '打印',
                      type: 'primary',
                      btnType: 'dropdown',
                    }" :check="selectionList" />
                </div>
              </template>
            </operatingButton>
            <el-table v-loading="loadingTable" ref="multipleTable" @row-click="handleRowClick" tooltip-effect="dark"
              :data="tableData" @selection-change="handleSelectionChange" border :max-height="tableHeight"
              :height="tableHeight">
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
              <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" width="180">
                <template slot-scope="scope">
                  <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                    {{ scope.row.billNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="单据日期" align="center" prop="billDate" width="155" />
              <el-table-column label="手工单据号" align="center" prop="handBillNo" width="160" />
              <el-table-column label="单据状态" align="center" prop="billStatusName" width="94" />
              <el-table-column label="数量" align="center" prop="billQty" width="110" />
              <el-table-column label="金额" align="center" prop="billMoney" width="110" />
              <el-table-column label="生产质检状态" align="center" prop="productCheckStatusName" width="100" />
              <el-table-column label="成品质检状态" align="center" prop="qualityCheckStatusName" width="100" />
              <el-table-column label="入库状态" align="center" prop="inStoreStatusName" width="90" />
              <el-table-column label="生产工厂" align="center" prop="produceDeptName" width="136" />
              <el-table-column label="部门" align="center" prop="deptName" width="136" />
              <el-table-column label="入库仓库" align="center" prop="inStoreName" width="126" />
              <el-table-column label="备注" align="center" prop="billRemark" min-width="155" />
              <el-table-column label="创建人" align="center" prop="createBy" width="120" />
              <el-table-column label="创建时间" align="center" prop="createTime" width="155" />

              <el-table-column label="修改人" align="center" prop="updateBy" width="120" />
              <el-table-column label="修改时间" align="center" prop="updateTime" width="155" />
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
              @selectAllCom="selectAll" />
          </div>
        </template>
      </cardTitleCom>
      <selectProductStorage :openInStore.sync="openInStore" :iSOpenStorage="iSOpenStorage" :inStoreForm="inStoreForm" />
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import bottomPage from "@/views/components/bottomPage"; //底部分页
import selectProductStorage from "@/views/components/selectProductStorage"; //入库弹窗
import {
  inStoreListAPI,
  inStoreUpdateStatusAPI,
  inStoreDetailAPI,
  listImportBillInfo
} from "@/api/produce/bill/inStore"; //产品入库
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "InStore",
  dicts: ["bill_status"],
  components: {
    PrintButton: () => import("@/components/tablePage/tableButtons/print.vue"),
    bottomPage,
    cardTitleCom,
    seniorSearch,
    selectProductStorage,
    operatingButton,
    SelectLocal,
    SelectRemote,
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        (vm.queryParams = {
          pageNum: 1,
          pageSize: 15,
          billStatuss: [to.query.billStatus],
        }),
          (vm.thisMonth = "");
        vm.getList();
      } else {
        vm.thisMonth = "thisMonth";
      }
    });
  },
  data() {
    return {
      thisMonth: "",
      selectionList: [],
      inStoreStatusList: [
        {
          inStoreId: 1,
          inStoreName: "未入库",
        },
        {
          inStoreId: 2,
          inStoreName: "部分入库",
        },
        {
          inStoreId: 3,
          inStoreName: "完全入库",
        },
      ], //入库状态
      qualityStatusList: [
        {
          qualityStatusId: 1,
          qualityStatusName: "未质检",
        },
        {
          qualityStatusId: 2,
          qualityStatusName: "部分质检",
        },
        {
          qualityStatusId: 3,
          qualityStatusName: "完全质检",
        },
      ], //质检状态
      inStoreForm: {}, //质检单据详情
      iSOpenStorage: undefined, //仓库弹窗的字段控制
      openInStore: false, //入库弹窗开启按钮
      loadingTable: false,
      multiple: true, // 非单个禁用
      single: true, //生产按钮单个禁用
      singleQualityCheck: true, //成品按钮禁用
      singleInStore: true, //入库按钮禁用
      showHigh: false, //是否显示高级搜索
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 84;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //相关单据
    async getRelevantDocuments() {
      if (this.ids.length != 1) {
        return this.$message.error('请勾选一条数据查询相关单据！')
      }
      const res = await listImportBillInfo(this.ids[0])
      let data = res.data || []
      this.$router.push({
        name: 'produceInStoreRelevant',
        query: {
          type: 'produceInStore',
          data: JSON.stringify(data)
        }
      })
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //打开质检、入库弹窗
    async getOpenInStore(val) {
      if (val == 4) {
        this.$router.push({
          name: 'AutoDistribute',
          // query: {
          //   billIds: JSON.stringify(this.billStatusZeroId),
          //   type: 'inProduceStore'
          // }
        })
      } else {
        this.iSOpenStorage = val;
        const { data } = await inStoreDetailAPI(this.billStatusZeroId[0]);
        this.inStoreForm = data;
        this.openInStore = true;
      }
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      const { rows, total } = await inStoreListAPI(this.queryParams);
      this.tableData = rows;
      this.total = total;
      this.loadingTable = false;
    },

    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error("勾选单据中没有未审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: "2",
            billType: "170203",
          };
          //发送审核api
          await inStoreUpdateStatusAPI(obj);
          // 弹出提示
          this.$message.success("审核成功");
        } else if (name === "反审核") {
          if (this.billStatusTwoId.length === 0) {
            this.$message.error("勾选单据中没有已审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusTwoId,
            billStatus: "0",
            billType: "170203",
          };
          //发送反审核api
          await inStoreUpdateStatusAPI(obj);
          // 弹出提示
          this.$message.success("反审核成功");
        } else if (name === "删除") {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error("勾选单据中没有未审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: "3",
            billType: "170203",
          };
          //发送删除api
          await inStoreUpdateStatusAPI(obj);
          // 弹出提示
          this.$message.success("删除成功");
        }
        await this.getList();
      } catch { }
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectionList = selection;
      this.ids = selection.map((item) => item.billId); //单据id
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map((item) => {
          if (item.billStatus == "0") {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map((item) => {
          if (item.billStatus == "2") {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map((item) => {
          if (item.billStatus != "3" && item.billStatus == "0") {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      this.multiple = !selection.length;
      if (selection.length == 1 && this.billStatusZeroId.length == 1) {
        if (selection[0].productCheckStatus == 1) {
          //生产
          this.single = false;
          //成品
          this.singleQualityCheck = true;
          //入库
          this.singleInStore = true;
        } else if (
          selection[0].productCheckStatus == 3 &&
          selection[0].qualityCheckStatus == 1
        ) {
          //生产
          this.single = true;
          //成品
          this.singleQualityCheck = false;
          //入库
          this.singleInStore = true;
        } else if (
          selection[0].productCheckStatus == 3 &&
          selection[0].qualityCheckStatus == 3
        ) {
          //生产
          this.single = true;
          //成品
          this.singleQualityCheck = true;
          //入库
          this.singleInStore = false;
        } else if (
          selection[0].productCheckStatus == 3 &&
          selection[0].qualityCheckStatus == 3 &&
          selection[0].inStoreStatus == 3
        ) {
          //生产
          this.single = true;
          //成品
          this.singleQualityCheck = true;
          //入库
          this.singleInStore = true;
        }
      } else {
        //生产
        this.single = true;
        //成品
        this.singleQualityCheck = true;
        //入库
        this.singleInStore = true;
      }
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        const res = await inStoreListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: "inStoreDetail",
      });
    },
    //跳转详情
    handleDetail(row) {
      const billId = row.billId;
      this.$router.push({
        name: "inStoreDetail",
        query: {
          billId: billId,
          type: "Update",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
    .tableBtn {
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      //生产部质检
      .ProductionTeste {
        color: #ffffff;
        background-color: #f2880c;
      }
      //产品部质检
      .endProductTeste {
        color: #ffffff;
        background-color: #00b7c4;
      }
      //入库
      .putStorage {
        color: #ffffff;
        background-color: #0dc46a;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 6px;
}
</style>
